import React, { useCallback, useState } from 'react';
import { Button, Form, Modal, ModalProps } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';
import classNames from 'classnames';

import { useHandleError } from '@/hooks';
import { LoadingButton } from '@/components/loading-button';
import { CHALLENGE_NAMES } from '@/lib/models';

interface MfaCodeEntryModalProps extends ModalProps {
	user: CognitoUser;
	onSuccess(user: CognitoUser): void;
}

export const MfaCodeEntryModal = ({ user, onSuccess, ...props }: MfaCodeEntryModalProps) => {
	const handleError = useHandleError();
	const [isLoading, setIsLoading] = useState(false);
	const [codeInputValue, setCodeInputValue] = useState('');

	const handleOnEnter = useCallback(() => {
		setIsLoading(false);
		setCodeInputValue('');
	}, []);

	async function handleFormSubmit(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();

		try {
			setIsLoading(true);

			const confirmedUser: CognitoUser = await Auth.confirmSignIn(
				user,
				codeInputValue.replace(/\s/g, ''),
				(user.challengeName as unknown) as undefined
			);

			onSuccess(confirmedUser);
		} catch (error) {
			if ((error as any).code === 'CodeMismatchException' || (error as any).name === 'CodeMismatchException') {
				(error as any).message = 'Code is invalid.';
			}

			setCodeInputValue('');
			handleError(error);
		} finally {
			setIsLoading(false);
		}
	}

	return (
		<Modal centered onEnter={handleOnEnter} {...props}>
			<Modal.Header>
				<Modal.Title>Multi-Factor Authentication</Modal.Title>
			</Modal.Header>
			<Form onSubmit={handleFormSubmit}>
				<Modal.Body>
					<Form.Group
						className={classNames('mb-0', {
							'mb-2':
								user.challengeName === CHALLENGE_NAMES.SOFTWARE_TOKEN_MFA ||
								user.challengeName === CHALLENGE_NAMES.SMS_MFA,
						})}
					>
						<Form.Label>Authentication Code</Form.Label>
						<Form.Control
							type="tel"
							value={codeInputValue}
							onChange={(event) => setCodeInputValue(event.target.value)}
							required
						/>
					</Form.Group>
					{user.challengeName === CHALLENGE_NAMES.SOFTWARE_TOKEN_MFA && (
						<p className="mb-0">
							Open your authentication app (TOTP) on your mobile device to view your authentication code.
						</p>
					)}
					{user.challengeName === CHALLENGE_NAMES.SMS_MFA && (
						<p className="mb-0">
							We have delivered the authentication code via SMS. Please enter the code to complete
							authentication.
						</p>
					)}
				</Modal.Body>
				<Modal.Footer>
					<div className="d-flex justify-content-between align-items-center">
						<a href="mailto:advisorysupport@poweredbyhackett.com" className="d-block">
							Contact Us
						</a>
						<div>
							<Button className="mr-4" variant="link" onClick={props.onHide}>
								Close
							</Button>
							<LoadingButton
								isLoading={isLoading}
								disabled={!codeInputValue}
								variant="primary"
								type="submit"
							>
								Submit
							</LoadingButton>
						</div>
					</div>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};
