import React from 'react';
import { Link } from 'react-router-dom';

import { createUseThemedStyles } from '@/hooks';
import { boxShadow } from '@/jss';

import { ReactComponent as HackettConnectLogo } from '@/assets/logos/logo-hackett-connect.svg';
// import { ReactComponent as HackettLogo } from '@/assets/logos/logo-hackett-r.svg';

const useStyles = createUseThemedStyles((theme) => ({
	headerNoAuth: {
		...boxShadow,
		display: 'flex',
		padding: '20px 40px',
		alignItems: 'center',
		justifyContent: 'space-between',
		backgroundColor: theme.colors.white,
	},
	hackettConnectLogo: {
		width: 390,
		height: 'auto',
		display: 'block',
	},
}));

export const HeaderNoAuth = () => {
	const classes = useStyles();

	return (
		<header className={classes.headerNoAuth}>
			<h1 className="mb-0">
				<Link to="/" className="text-primary">
					<HackettConnectLogo className={classes.hackettConnectLogo} />
				</Link>
			</h1>
		</header>
	);
};
